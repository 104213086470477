import { getLevelConfigArray } from "exercises/config_generators/utils"

const LEVELS = {
  level_1: { stains: 6, duration: 1, timeout: null, next_stain: null, types: "P", double: 0 },
  level_2: { stains: 7 },
  level_3: { stains: 8 },
  level_4: { duration: 1.5 },
  level_5: { stains: 9, duration: 2 },
  level_6: { stains: 10, timeout: 8, next_stain: 7 },
  level_7: { stains: 11, timeout: 6.5, next_stain: 5.5 },
  level_8: { stains: 12, timeout: 5, next_stain: 4, types: "PL" },
  level_9: { stains: 13 },
  level_10: { stains: 14, duration: 2.2 },
  level_11: { stains: 15, duration: 2.4 },
  level_12: { stains: null, duration: 2.2, double: 0.25 },
  level_13: { stains: null, double: 0.45 },
  level_14: {
    stains: null,
    duration: 2.5,
    timeout: 4.8,
    next_stain: 4,
    types: "PLK",
    double: 0.65,
  },
  level_15: { stains: null, pointsPerStain: 2, double: 0.25 },
  level_16: { stains: null, duration: 2.6, double: 0.3 },
  level_17: { stains: null, double: 0.35 },
  level_18: { stains: null, duration: 2.7, double: 0.4 },
  level_19: { stains: null, duration: 3, double: 0.47 },
  level_20: { stains: null, double: 0.5 },
  level_21: { stains: null, double: 0.55 },
  level_22: {
    stains: null,
    duration: 3.2,
    timeout: 5,
    next_stain: 5,
    double: 0.3,
    pointsPerStain: 3,
  },
  level_23: { stains: null, duration: 3.5, double: 0.35 },
  level_24: { stains: null, duration: 3.8, next_stain: 4.9, double: 0.4 },
  level_25: { stains: null, duration: 4, timeout: 5.2, next_stain: 5.2, double: 0.5 },
  level_26: {
    stains: null,
    duration: 4.2,
    timeout: 5.3,
    next_stain: 5.3,
    double: 0.3,
    pointsPerStain: 4,
  },
  level_27: { stains: null, duration: 4.5, timeout: 5.5, next_stain: 5.5, double: 0.4 },
  level_28: { stains: null, duration: 5, timeout: 5.9, next_stain: 5.9, double: 0.6 },
}
const DEFAULTS = {
  stains: 5,
  duration: 1,
  timeout: null,
  next_stain: null,
  types: "P",
  double: false,
  pointsPerStain: 1,
}
const WIPER_LEVELS = getLevelConfigArray(LEVELS, DEFAULTS, "wiper")
export default WIPER_LEVELS
