import ExerciseDescriptor, { areaName, AREAS } from "../exercise_descriptor"
import MACHINE_LEVELS from "./machine_levels"
import ConfigGenerator from "../../config_generators/config_generator"
import { omit, pick } from "lodash"
import rand from "utils/rand"
import { MachinePositionGenerator } from "exercises/descriptors/machine/MachinePositionGenerator"

const ANIMATIONS_DURATION = 1.0
const NON_TARGET_DURATION = 2

export class MachineConfigGenerator extends ConfigGenerator {
  NON_TARGET_DURATION = NON_TARGET_DURATION

  constructor(level_conf, areas) {
    super(level_conf, areas)
    this._pos_generator = new MachinePositionGenerator(
      this._areas,
      level_conf.range,
      this.MIN_DISTANCE
    )
  }

  static minTrialDuration(conf) {
    return (conf.global_timeout < 0 ? 22 : conf.global_timeout) * 0.9 + ANIMATIONS_DURATION
  }

  generateScenario(round_duration) {
    const minDuration = Math.min(
      MachineConfigGenerator.minTrialDuration(this._conf),
      ANIMATIONS_DURATION + this.MIN_REACTION_TIME / 1000
    )
    const trials = round_duration / minDuration
    while (this.scenario.length < trials) {
      const trial = this.generateTrial()
      if (trial) this.scenario.push(trial)
      else this.scenario.pop()
    }
    return this.scenario
  }

  generateRoundConfig(round_duration) {
    return {
      ...super.generateRoundConfig(round_duration),
      left_machine: {
        x: -0.4,
        y: 0,
      },
      right_machine: {
        x: 0.4,
        y: 0,
      },
      scale: 1.1,
      non_target_hide_duration: this.NON_TARGET_DURATION,
      ...pick(this._conf, "target_score", "nontarget_score", "timeout_score"),
    }
  }

  generateTrial() {
    const {
      nontarget_probability,
      distractors_number,
      randomizer_type,
      combined_number,
    } = this._conf
    const last = this.scenario[this.scenario.length - 1]
    const isTarget =
      last == null || last.isTarget === false ? true : !rand.bool(nontarget_probability)
    const distractors = isTarget && rand.bool(combined_number) ? rand.int(...distractors_number) : 0
    const positions = this._pos_generator.getPositions(randomizer_type, distractors)
    return {
      id: "Machine_" + this.scenario.length,
      isTarget,
      timeout: isTarget
        ? this._conf.global_timeout
        : Math.min(this.NON_TARGET_DURATION, this._conf.global_timeout),
      activation_duration: 0.2,
      nontarget_activation_duration: 0.05,
      second_target_timeout: this._conf.second_target_timeout,
      buttons: positions.map((p, i) => ({
        id: `Machine_${this.scenario.length}_${i}_${p.s}`,
        side: p.s,
        position: p.i,
        is_target: isTarget && i < 2,
      })),
    }
  }
}

export default class MachineDesc extends ExerciseDescriptor {
  generateRoundConfig(run_config) {
    const config = super.generateRoundConfig(run_config)
    config.scenario = config.scenario.map((s) => ({
      ...omit(s, "isTarget"),
    }))
    return config
  }

  getExpectedPoints(run_config) {
    const conf = this._getLevelConfig(run_config)
    const { round_duration } = run_config
    const trialDuration = MachineConfigGenerator.minTrialDuration(conf) - ANIMATIONS_DURATION
    const nonTargetDuration = Math.min(trialDuration, NON_TARGET_DURATION)
    const nonTargets = conf.nontarget_probability
    const avgTrialDuration =
      (trialDuration + nonTargets * nonTargetDuration) / (1 + nonTargets) + ANIMATIONS_DURATION
    const expectedTrials = round_duration / avgTrialDuration
    let expectedNonTargets = expectedTrials * nonTargets
    expectedNonTargets =
      (expectedNonTargets / (expectedNonTargets + expectedTrials)) * expectedTrials
    const expectedTargets = expectedTrials - expectedNonTargets
    return expectedTargets * conf.target_score
  }
}

Object.assign(MachineDesc.prototype, {
  scene_name: "Machine",
  exercise_id: "machine",
  LEVELS_CONFIG: MACHINE_LEVELS,
  GeneratorClass: MachineConfigGenerator,
  instructions: [{ video: { slides: "machine" } }, { hint: _tnoop("hints:machine.instruction") }],
  available_areas: ExerciseDescriptor.prototype.available_areas.filter(
    (a) => a.length > 2 || (a.length === 2 && a[0][1] !== a[1][1])
  ),
})
